import { Map } from "modules/map";
import React from "react";
import { Legend, Sidebar } from "./components";
import { Modal } from "./modules/modal";
import { HelpButton } from "./modules/help-button";

function App() {
  return (
    <div className="app-root">
      <Modal />
      <Map />
      <Sidebar />
      <HelpButton />
      <Legend />
    </div>
  );
}

export default App;
