import axios from "../../config/axios";
import { useQuery } from "react-query";

const getGridStat = async () => {
  const data = await axios.get(`/polygons/filters/`);
  return data;
};

const getGridFields = async () => {
  const data = await axios.get(`/polygons/naming/`);
  return data;
};

export const useGetGridStat = (enabled = true) => {
  return useQuery(["grid-stat"], () => getGridStat(), { enabled });
};

export const useGetGridFields = (enabled = true, select) => {
  return useQuery(["grid-fields"], () => getGridFields(), { enabled, select });
};

export const useGetGridStyleFields = (enabled = true) => {
  return useGetGridFields(enabled, (data) => {
    const { id, cellid, geometry, ...rest } = data;
    return rest;
  });
};

export const useGetFilters = () => {
  const { data: statistics } = useGetGridStat();
  const { data: styleFieldInfo } = useGetGridStyleFields();

  if (!statistics || !styleFieldInfo) return;

  return Object.entries(styleFieldInfo).reduce((result, [field, info]) => {
    const fieldStat = statistics[field];
    result[field] = {
      label: info.label,
      min: fieldStat[0],
      max: fieldStat[fieldStat.length - 1],
    };
    return result;
  }, {});
};
