import { scaleLinear, scaleThreshold } from "d3-scale";
import { ckmeans } from "simple-statistics";
import { paletteColorsCount } from "../config/styles";

export const getDataRange = (values) => {
  return [Math.min(...values), Math.max(...values)];
};

export const createScale = (
  data,
  outputRange,
  type = "grid",
  method = "linear"
) => {
  switch (method) {
    case "jenks":
      const jenks = ckmeans(data, paletteColorsCount);
      const jenksIntervals = jenks.map((i) => getDataRange(i));
      jenksIntervals.pop();
      return scaleThreshold(
        jenksIntervals.map((interval) => interval[1]),
        outputRange
      );
    case "linear":
    default:
      const domain = type === "grid" ? [data[0], data[data.length - 1]] : data;
      return scaleLinear(domain, outputRange);
  }
};
