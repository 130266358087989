import { createSelector } from "@reduxjs/toolkit";

const getRootState = (state) => state.root;

export const getGridStyleField = createSelector(
  getRootState,
  (state) => state.gridStyleField["fieldName"]
);

export const getVisible = (layerId) =>
  createSelector(getRootState, (state) => state.visible[layerId]);

export const getMinOptimaValue = createSelector(
  getRootState,
  (state) => state.minOptimaValue
);

export const getMaxDensityValue = createSelector(
  getRootState,
  (state) => state.maxDensityValue
);

export const getMinTransportValue = createSelector(
  getRootState,
  (state) => state.minTransportValue
);

export const getShowModal = createSelector(
  getRootState,
  (state) => state.showModal
);

export const getGridFilters = createSelector(
  getRootState,
  (state) => state.gridFilters
);
