export const MAP_CENTER_DEFAULT = [55.7558, 37.6173];
export const MAP_ZOOM_DEFAULT = 12;
export const MIN_ZOOM_DEFAULT = 10;
export const TILE_SERVER_STADIA_URL =
  "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png";
export const TILE_SERVER_STADIA_ATTRIBUTION =
  '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors';
export const TILE_SERVER_OSM_URL =
  "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
export const TILE_SERVER_OSM_ATTRIBUTION =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

export const TILE_SERVER_URL = TILE_SERVER_STADIA_URL;
export const TILE_SERVER_ATTRIBUTION = TILE_SERVER_STADIA_ATTRIBUTION;

export const QUERY_KEYS = {
  POINTS: "POINTS",
};

export const days = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];

export const gridFields = {
  home: "home",
  job: "job",
  horeca: "horeca",
  brand: "brand",
  university: "univer",
  hotel: "hotel",
  shoppingCenter: "TC",
  businessCenter: "BC",
  metro: "metro",
};

export const pointFields = {
  id: "id",
  name: "name",
  address: "adress",
  district: "district",
  link: "link",
  area: "area",
  price: "price",
};

export const settingsHelp =
  "Найдите оптимальное место для размещения бизнеса с помощью фильтров бизнес-факторов";

export const polygonsPbfUrl = `${process.env.REACT_APP_DOMAIN_URL}/martin/public.service_polygon/{z}/{x}/{y}.pbf`;
