import { useSelector } from "react-redux";
import {
  getGridFilters,
  getGridStyleField,
} from "../../root-slice/root-selectors";
import { useGetGridStat } from "../../api/grid";
import React, { useMemo } from "react";
import {
  DEFAULT_GRID_STYLE,
  getScaleMethod,
  gridColorMap,
} from "../../config/styles";
import { createScale } from "../../utils";
import * as ReactDOMServer from "react-dom/server";
import { Popup } from "../point-popup";
import { gridFeatureInfo } from "../../config/popup";
import VectorGrid from "../../components/vector-grid";
import { withLoading } from "../withLoading";
import { polygonsPbfUrl } from "../../config/constants";

const AsyncGrid = withLoading(VectorGrid);

const isOutRange = (value, range) => {
  const [min, max] = [range[0], range[1]];
  return value < min || value > max;
};

export const GridLayer = () => {
  const gridStyleField = useSelector(getGridStyleField);
  const filters = useSelector(getGridFilters);
  const { data: gridStat, status } = useGetGridStat();

  const colorScale = useMemo(() => {
    if (!gridStat) return undefined;

    return createScale(
      gridStat[gridStyleField],
      gridColorMap[gridStyleField],
      "grid",
      getScaleMethod(gridStyleField)
    );
  }, [gridStat, gridStyleField]);

  const options = useMemo(() => {
    if (!gridStat) {
      return {
        type: "protobuf",
        url: polygonsPbfUrl,
        interactive: true,
        vectorTileLayerStyles: {
          "public.service_polygon": {
            ...DEFAULT_GRID_STYLE,
          },
        },
      };
    }

    return {
      type: "protobuf",
      url: polygonsPbfUrl,
      interactive: true,
      vectorTileLayerStyles: {
        "public.service_polygon": function (properties) {
          let hidePoly = properties[gridStyleField] === 0;

          Object.entries(filters).forEach(([field, range]) => {
            const value = properties[field];
            hidePoly = hidePoly || isOutRange(value, range);
          });

          return {
            ...DEFAULT_GRID_STYLE,
            fillColor: colorScale(properties[gridStyleField]),
            fillOpacity: hidePoly ? 0 : 0.8,
            fill: !hidePoly,
            stroke: !hidePoly,
          };
        },
      },
      popup: (feature) =>
        ReactDOMServer.renderToString(
          <Popup feature={feature} config={gridFeatureInfo} />
        ),
    };
  }, [gridStat, gridStyleField, colorScale, filters]);

  const gridLayerKey = `${gridStat}-${gridStyleField}-${colorScale}-${JSON.stringify(
    filters
  )}`;

  return <AsyncGrid status={status} {...options} key={gridLayerKey} />;
};
