import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import {
  MAP_CENTER_DEFAULT,
  MAP_ZOOM_DEFAULT,
  TILE_SERVER_ATTRIBUTION,
  TILE_SERVER_URL,
  MIN_ZOOM_DEFAULT,
} from "config/constants";
import { GridLayer, PointLayer } from "../layers";
import { useSelector } from "react-redux";
import { getVisible } from "../../root-slice/root-selectors";

export const Map = () => {
  const pointsVisible = useSelector(getVisible("points"));
  const gridVisible = useSelector(getVisible("grid"));

  return (
    <MapContainer
      center={MAP_CENTER_DEFAULT}
      zoom={MAP_ZOOM_DEFAULT}
      minZoom={MIN_ZOOM_DEFAULT}
    >
      <TileLayer attribution={TILE_SERVER_ATTRIBUTION} url={TILE_SERVER_URL} />

      {gridVisible && <GridLayer />}
      {pointsVisible && <PointLayer />}
    </MapContainer>
  );
};
