import styles from "./sidebar.module.scss";
import { Checkbox, Divider, IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getVisible } from "../../root-slice/root-selectors";
import { toggleVisibility } from "../../root-slice/root-slice";
import { GridStyleOptions } from "./grid-style-options";
import { useCallback, useState } from "react";
import { StyledFormControlLabel } from "../../components/form-controls";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../components/accordion";
import { Filters } from "../filters";
import HelpIcon from "@mui/icons-material/Help";
import { settingsHelp } from "../../config/constants";

export const Sidebar = () => {
  const [filtersVisible, setFiltersVisible] = useState(false);

  const toggleFiltersVisible = useCallback(() => {
    setFiltersVisible(!filtersVisible);
  }, [filtersVisible]);

  const pointsVisible = useSelector(getVisible("points"));
  const dispatch = useDispatch();

  const changePointsVisibility = useCallback(() => {
    dispatch(toggleVisibility("points"));
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <StyledFormControlLabel
        control={
          <Checkbox checked={pointsVisible} onChange={changePointsVisibility} />
        }
        label="Коммерческая недвижимость"
      />
      <Divider style={{ paddingTop: "0.5rem" }} />
      <GridStyleOptions />
      <Divider style={{ paddingTop: "0.5rem" }} />
      <Accordion expanded={filtersVisible} onChange={toggleFiltersVisible}>
        <AccordionSummary aria-controls="filters-content" id="filters-header">
          Настройки
          <Tooltip title={settingsHelp} placement={"top"}>
            <IconButton size={"small"}>
              <HelpIcon
                fontSize={"small"}
                style={{ fill: "rgba(0, 0, 0, 0.1)" }}
              />
            </IconButton>
          </Tooltip>
        </AccordionSummary>
        <AccordionDetails>
          <Filters />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
