import { useGetFilters } from "../../api/grid";
import { useDebouncedCallback } from "use-debounce";
import { Slider } from "../../components/slider";
import styles from "./filters.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setGridFilter } from "../../root-slice/root-slice";
import { getGridFilters } from "../../root-slice/root-selectors";

export const Filters = () => {
  const filters = useGetFilters();
  const dispatch = useDispatch();
  const handleFilterChange = useDebouncedCallback((field, value) => {
    dispatch(setGridFilter({ field, value }));
  }, 300);
  const currentFilters = useSelector(getGridFilters);

  if (!filters) return null;

  return (
    <div className={styles.root}>
      {Object.entries(filters).map(([field, config]) => {
        const { label, min, max } = config;
        const curFieldRange = currentFilters[field];
        const value = curFieldRange
          ? [curFieldRange[0], curFieldRange[1]]
          : [min, max];

        return (
          <Slider
            key={field}
            name={field}
            title={label}
            min={min}
            max={max}
            value={value}
            onChange={handleFilterChange}
            marks={{
              [min]: min,
              [value[0]]: value[0],
              [value[1]]: value[1],
              [max]: max,
            }}
          />
        );
      })}
    </div>
  );
};
