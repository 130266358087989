import React from "react";
import { Grid } from "@mui/material";
import styles from "./popup.module.scss";
import { formatters } from "../../utils/formatters";
import { isNill } from "../../utils/guards";

export const Popup = ({ feature, config }) => {
  const { properties: props } = feature;

  return (
    <div className={styles.container}>
      {config.title && props[config.title] && (
        <div className={styles.title}>{props[config.title]}</div>
      )}
      {config.rows.map((row, index) => {
        const value = props[row.fieldName];
        if (value === "nan" || isNill(value)) {
          return null;
        }
        const { formatter: formatterName } = row;
        const formatter = formatterName && formatters[formatterName];
        const formattedValue = formatter ? formatter(value) : value;

        return (
          <Grid
            container
            key={row.fieldName}
            className={styles.row}
            style={{ backgroundColor: index % 2 === 0 ? "white" : "#ecebeb" }}
          >
            <Grid item xs={6} className={styles.propName}>
              {row.label}
            </Grid>
            <Grid item xs={6} className={styles.propValueCol}>
              {formattedValue}
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};
