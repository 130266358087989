import { gridFields } from "./constants";

export const DEFAULT_MARKER_STYLE = {
  weight: 0,
  opacity: 1,
  fillOpacity: 1,
};

export const DEFAULT_GRID_STYLE = {
  fill: true,
  stroke: true,
  opacity: 0.1,
  weight: 1,
  color: "grey",
  fillOpacity: 0.9,
};

export const POINT_COLOR = "#272979";

export const POINT_RADIUS = 2.6;

export const paletteColorsCount = 13;

export const gridColorMap = {
  [gridFields.home]: [
    "#5A0F4F",
    "#681268",
    "#691676",
    "#661A84",
    "#611E91",
    "#59229E",
    "#4E27AB",
    "#4F42B8",
    "#5D65C5",
    "#7990D1",
    "#96B5DC",
    "#B3D4E7",
    "#D1EBF1",
  ].reverse(),
  [gridFields.job]: [
    "#272979",
    "#2E3E8C",
    "#36579F",
    "#3E72B1",
    "#4691C3",
    "#4EB2D5",
    "#57D6E7",
    "#6EEFEC",
    "#87F5E3",
    "#A1F9DF",
    "#BCFDE1",
    "#D9FFE9",
    "#F7FFFA",
  ].reverse(),
  [gridFields.horeca]: [
    "#681308",
    "#780B0E",
    "#880D22",
    "#97113B",
    "#A71457",
    "#B61876",
    "#C41C98",
    "#CE38C3",
    "#C954D8",
    "#C072E1",
    "#BE90E9",
    "#C4AFF0",
    "#D4CEF7",
  ].reverse(),
  [gridFields.brand]: [
    "#010202",
    "#0C1E26",
    "#17414B",
    "#216B71",
    "#2A9792",
    "#32BFA8",
    "#39E7B7",
    "#4EF7D0",
    "#67FFE6",
    "#85FFF7",
    "#A8FFFF",
    "#D2FFFF",
    "#FCFFFF",
  ].reverse(),
  [gridFields.university]: [
    "#092316",
    "#0D382E",
    "#114D4E",
    "#144E65",
    "#16457C",
    "#183294",
    "#2019AC",
    "#3857BE",
    "#5995CE",
    "#7CC8DC",
    "#A1E9E4",
    "#C8F3E4",
    "#F0FCF4",
  ].reverse(),
  [gridFields.hotel]: [
    "#13142C",
    "#1D2346",
    "#273662",
    "#304D7D",
    "#39699A",
    "#4189B7",
    "#48AED4",
    "#60B7DF",
    "#7AC0E9",
    "#95CBF1",
    "#B3D8F7",
    "#D1E6FC",
    "#F2F8FE",
  ].reverse(),
  [gridFields.shoppingCenter]: [
    "#301409",
    "#4D170D",
    "#6B1410",
    "#8A131B",
    "#AA1431",
    "#CB144D",
    "#ED1470",
    "#F7327C",
    "#FE528B",
    "#FF789D",
    "#FFA0B4",
    "#FFC8CF",
    "#FFF0F1",
  ].reverse(),
  [gridFields.businessCenter]: [
    "#23300A",
    "#3F4D0F",
    "#616B13",
    "#8A8917",
    "#AA9819",
    "#CAA11A",
    "#ECA21B",
    "#F6BC38",
    "#FDD257",
    "#FFE579",
    "#FFF3A0",
    "#FFFCC8",
    "#FFFFF0",
  ].reverse(),
  [gridFields.metro]: [
    "#292E11",
    "#484A1B",
    "#676223",
    "#84742B",
    "#A28032",
    "#C18839",
    "#E08A3F",
    "#EAA358",
    "#F2BA73",
    "#F9CF8F",
    "#FDE2AE",
    "#FFF1CF",
    "#FFFCF1",
  ].reverse(),
};

export const getScaleMethod = (field) => {
  switch (field) {
    case gridFields.home:
    case gridFields.job:
    case gridFields.horeca:
    case gridFields.brand:
    case gridFields.university:
    case gridFields.hotel:
    case gridFields.shoppingCenter:
    case gridFields.businessCenter:
    case gridFields.metro:
      return "jenks";
    default:
      return "linear";
  }
};
