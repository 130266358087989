import React, { useCallback } from "react";
import { withLoading } from "../withLoading";
import { GeoJSON } from "react-leaflet";
import * as L from "leaflet";
import {
  DEFAULT_MARKER_STYLE,
  POINT_COLOR,
  POINT_RADIUS,
} from "../../config/styles";
import * as ReactDOMServer from "react-dom/server";
import { Popup } from "../point-popup";
import { pointFeatureInfo } from "../../config/popup";
import { useGetPoints } from "../../api";

const AsyncPoints = withLoading(GeoJSON);

export const PointLayer = () => {
  const { data, status } = useGetPoints();

  const pointToLayer = useCallback((feature, latlng) => {
    return L.circleMarker(latlng, {
      ...DEFAULT_MARKER_STYLE,
      fillColor: POINT_COLOR,
      fillOpacity: 0.8,
      opacity: 0.5,
      radius: POINT_RADIUS,
    });
  }, []);

  const onEachFeature = useCallback((feature, layer) => {
    const popupContent = ReactDOMServer.renderToString(
      <Popup feature={feature} config={pointFeatureInfo} />
    );
    layer.bindPopup(popupContent);
  }, []);

  const pointLayerKey = `points`;

  return (
    <AsyncPoints
      data={data}
      status={status}
      pointToLayer={pointToLayer}
      onEachFeature={onEachFeature}
      key={pointLayerKey}
    />
  );
};
