import { useQuery } from "react-query";
import { QUERY_KEYS } from "config/constants";
import { useCallback } from "react";
import { getPoints } from "./api";

export const useGetPoints = (enabled = true, select) => {
  const queryFn = useCallback(() => getPoints(), []);
  return useQuery(QUERY_KEYS.POINTS, queryFn, {
    enabled,
    select,
  });
};
