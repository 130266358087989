import { Button, Checkbox, IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { getGridStyleField, getVisible } from "../../root-slice/root-selectors";
import styles from "./sidebar.module.scss";
import { useCallback } from "react";
import { setGridStyleField } from "../../root-slice";
import { StyledFormControlLabel } from "../../components/form-controls";
import { toggleVisibility } from "../../root-slice/root-slice";
import { useGetGridStyleFields } from "../../api/grid";
import classnames from "clsx";
import HelpIcon from "@mui/icons-material/Help";

const StyledButton = styled(Button)(() => ({
  textTransform: "none",
  textAlign: "left",
  color: "black",
  fontSize: "13px",
  justifyContent: "flex-start",
}));

export const GridStyleOptions = () => {
  const curGridStyleField = useSelector(getGridStyleField);
  const {
    data: gridFields,
    isLoading,
    isError,
    isSuccess,
  } = useGetGridStyleFields();
  const gridVisible = useSelector(getVisible("grid"));
  const dispatch = useDispatch();

  const toggleField = useCallback(
    (e) => {
      dispatch(
        setGridStyleField({
          fieldName: e.target.name,
        })
      );
    },
    [dispatch]
  );

  const changeGridVisibility = useCallback(() => {
    dispatch(toggleVisibility("grid"));
  }, [dispatch]);

  return (
    <>
      <StyledFormControlLabel
        control={
          <Checkbox checked={gridVisible} onChange={changeGridVisibility} />
        }
        label="Бизнес-факторы"
      />
      <div className={styles.fieldsList}>
        {isLoading && <div>Загружаем данные...</div>}
        {isError && <div>Что-то пошло не так :(</div>}
        {isSuccess &&
          gridFields &&
          Object.entries(gridFields).map(([fieldName, info]) => {
            const isActive = curGridStyleField === fieldName;
            return (
              <div className={styles.itemContainer} key={fieldName}>
                <StyledButton
                  variant="text"
                  name={fieldName}
                  className={classnames(
                    styles.gridFieldButton,
                    isActive && styles.activeOption
                  )}
                  onClick={toggleField}
                >
                  {info.label}
                </StyledButton>
                {info.help && (
                  <div
                    style={{
                      flexBasis: "20%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title={info.help} placement={"top"}>
                      <IconButton size={"small"}>
                        <HelpIcon
                          fontSize={"small"}
                          style={{ fill: "rgba(0, 0, 0, 0.1)" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </>
  );
};
