import styles from "./legend.module.scss";
import { useSelector } from "react-redux";
import { getGridStyleField, getVisible } from "../../root-slice/root-selectors";
import { gridColorMap } from "../../config/styles";
import { useGetGridStat, useGetGridStyleFields } from "../../api/grid";

export const Legend = () => {
  const curGridStyleField = useSelector(getGridStyleField);
  const gridVisible = useSelector(getVisible("grid"));

  const { data: gridStat } = useGetGridStat();
  const { data: gridFields } = useGetGridStyleFields();

  const colorScale = gridColorMap[curGridStyleField];

  return (
    <div className={styles.root}>
      {gridVisible && gridStat && gridFields && (
        <div className={styles.legendBlockContainer}>
          <div className={styles.legendBlockTitle}>
            {gridFields[curGridStyleField].legend}
          </div>
          <div className={styles.colorRampContainer}>
            <div className={styles.colorRampContent}>
              <div
                className={styles.colorRamp}
                style={{
                  background: `linear-gradient(90deg, ${colorScale.join(",")})`,
                }}
              />
              <div className={styles.colorRampLabelContainer}>
                <div className={styles.colorRampLabel}>
                  {gridStat[curGridStyleField][0]}
                </div>
                <div className={styles.colorRampLabel}>
                  {
                    gridStat[curGridStyleField][
                      gridStat[curGridStyleField].length - 1
                    ]
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
