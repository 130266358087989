import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getShowModal } from "../../root-slice/root-selectors";
import { setShowModal } from "../../root-slice/root-slice";
import { Button } from "@mui/material";
import { useGetPoints } from "../../api";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "4px",
  outline: "none",
};

export const ModalComponent = () => {
  const open = useSelector(getShowModal);
  const { isLoading } = useGetPoints();
  const dispatch = useDispatch();

  const handleClose = useCallback(
    () => dispatch(setShowModal(false)),
    [dispatch]
  );

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div>
              <div style={{ textAlign: "center" }}>
                <p>Добро пожаловать!</p>
                <p>
                  Наш геоинформационный сервис поможет вам выбрать оптимальные
                  точки для открытия бизнеса. Наглядная тепловая карта позволит
                  оценить распределение факторов, важных именно для вашего вида
                  бизнеса, а гибкая настройка отображения - понять, в каких
                  именно районах города создаются условия, которые вы ищите.
                  Соотнесите получившиеся районы с существующими предложениями
                  по коммерческой недвижимости и выберите оптимальную точку
                  открытия.
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isLoading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <Button variant="text" onClick={handleClose}>
                    Найти место для бизнеса
                  </Button>
                )}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
