import { gridFields, pointFields } from "./constants";

export const pointFeatureInfo = {
  title: pointFields.name,
  rows: [
    {
      fieldName: pointFields.address,
      label: "Адрес",
    },
    {
      fieldName: pointFields.district,
      label: "Район",
    },
    {
      fieldName: pointFields.link,
      label: "Ссылка на объявление",
      formatter: "link",
    },
    {
      fieldName: pointFields.area,
      label: "Площадь помещения, кв. м",
      formatter: "area",
    },
    {
      fieldName: pointFields.price,
      label: "Цена аренды в месяц",
      formatter: "currency",
    },
  ],
};

export const gridFeatureInfo = {
  rows: [
    {
      fieldName: gridFields.businessCenter,
      label: "Кол-во бизнес-центров",
    },
    {
      fieldName: gridFields.shoppingCenter,
      label: "Кол-во торговых центров",
    },
    {
      fieldName: gridFields.job,
      label: "Численность работающего населения",
    },
    {
      fieldName: gridFields.home,
      label: "Чиcленность проживающего населения",
    },
    {
      fieldName: gridFields.brand,
      label: "Кол-во сильных конкурентов",
    },
    {
      fieldName: gridFields.hotel,
      label: "Кол-во отелей",
    },
    {
      fieldName: gridFields.metro,
      label: "Кол-во станций метро",
    },
    {
      fieldName: gridFields.horeca,
      label: "Кол-во точек общепита",
    },
    {
      fieldName: gridFields.university,
      label: "Кол-во университетов",
    },
  ],
};
