import { isNill } from "./guards";

const percentFormatter = (value) => {
  if (isNill(value)) return "Не удалось рассчитать процент";

  const converted = typeof value === "string" ? Number(value) : value;
  if (isNill(converted)) return "Не удалось рассчитать процент";

  return Math.round(value * 100);
};

const linkFormatter = (url, urlLabel = "https://avito.ru") => {
  return (
    <a href={`${url}`} target="_blank" rel="noreferrer">
      {urlLabel}
    </a>
  );
};

const areaFormatter = (value) => {
  return Math.round(value * 100) / 100;
};

const RubFormatter = new Intl.NumberFormat("ru-RU", {
  style: "currency",
  currency: "RUB",
});

const currencyFormatter = (value) => {
  return RubFormatter.format(value);
};

export const formatters = {
  percent: percentFormatter,
  link: linkFormatter,
  area: areaFormatter,
  currency: currencyFormatter,
};
