import { createSlice } from "@reduxjs/toolkit";
import { gridFields } from "../config/constants";

const initialState = {
  gridStyleField: {
    fieldName: gridFields.businessCenter,
  },
  visible: {
    points: true,
    grid: true,
  },
  showModal: true,
  gridFilters: {},
};

export const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setGridStyleField: (state, action) => {
      const { payload } = action;
      state.gridStyleField = payload;
    },

    setShowModal: (state, action) => {
      const { payload } = action;
      state.showModal = payload;
    },

    toggleVisibility: (state, action) => {
      const { payload } = action;
      state.visible[payload] = !state.visible[payload];
    },

    setGridFilter: (state, action) => {
      const {
        payload: { field, value },
      } = action;

      state.gridFilters[field] = value;
    },
  },
});

export const {
  setGridStyleField,
  toggleVisibility,
  setShowModal,
  setGridFilter,
} = rootSlice.actions;

export const rootReducer = rootSlice.reducer;
