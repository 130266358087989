import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_DOMAIN_URL}api/`,
  mode: "cors",
});

axiosInstance.interceptors.response.use((response) => {
  if ("data" in response) {
    return response.data;
  }
  return response;
});

export default axiosInstance;
